<template>
	<CModal title="UPDATE ODOMETER" :show.sync="modalState" color="info" size="md"> 
		<form @submit.prevent="approve">
    	<div class="pl-2 pr-2 pb-2"> 
    		<CRow class="mb-2">
        		<CCol lg="6">
        			Vehicle No.:
        		</CCol> 
				<CCol lg="6">
        			Vehicle Type:
        		</CCol> 
        	</CRow>
			<br>
        	<CRow class="mb-2">
        		<CCol lg="12">
        			<CInput
		                  label="Reading"
		                  placeholder="Enter reading"
						  
		                  v-model="bookingParams.remarks" 
		                />
        		</CCol> 
				<CCol lg="12">
								<div class="form-group"> 
									<label>Delivery Date
										<span class="text-danger"> *
										</span>
									</label>
									
									
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="dataParams.delivery_date"
										placeholder="Delivery date" 
										:typeable="true" 
										required 
									>
									</Datepicker>  
								</div>
							</CCol> 
        	</CRow>
        	
    	</div>
    	<div slot="footer" class="w-100">
    		<CButton size="sm" style="border-radius: .2rem; color: white;" color="success" class="ml-1 mr-1 float-right" type="submit">
              Save
            </CButton> 
    			<CButton size="sm" style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="formModal = false">
	                    Cancel
	            </CButton>
        </div>
        </form>
    	<div slot="footer" class="w-100"></div>
    </CModal>
</template>
<script>
	import config from '../../config.js';
	import axios from '../../axios';
	import Swal from 'sweetalert2'; 
	import Datepicker from 'vuejs-datepicker';
	import vSelect from 'vue-select'
	import 'vue-select/dist/vue-select.css';

	export default{

		props: {
			showModal: false,
			details: {
				remarks: ""
			}
		},
		
		data(){
			return{
				bookingParams: {
					remarks: ""
				},
				dataParams: {
					status: "approved"
				},
				modalState: false,
			}
			
		},
		
		watch: {
			details: {
				deep: true,
				handler(){
					this.bookingParams.remarks = this.details.remarks;
				}
			},
			showModal: {
				handler(){
					this.modalState = this.showModal
					if(!this.showModal){
						this.$emit('showModal', false) 
					} 
				}
			},
			modalState: {
				handler(){
					if(!this.modalState){
						this.$emit('showModal', false) 
					} 
				}
			}
		},
		components: {Datepicker, vSelect},
		
		methods: {

			approve(){

				// axios.post(config.api_path+'/bookingStatusDetail', this.dataParams)
				// .then(response=>{ 

					
					this.bookingParams.user_id = config.getUserDetail('id');
					this.bookingParams.status_update = true;
					
					this.bookingParams.status = "approved";
					axios.put(config.api_path+'/booking/'+this.details.id, this.bookingParams)
					
	    			Swal.fire({
					title: 'Success!',
					text: 'Booking successfully update status to approved!',
					icon: 'success', 
				})
					.then(response=>{
						this.$emit('showModal', false)
					})

				// })
			},
			
			decline(){ 
	    		Swal.fire({
					//  title: 'Declined?',
						text: "Are you sure you want to decline this booking?",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes,decline!'
						}).then((result) => {
						if (result.isConfirmed) {  
							axios.put(config.api_path+'/booking/'+this.details.id, {status: "declined", remarks: this.bookingParams.remarks, user_id: config.getUserDetail('id'), status_update: true})
							Swal.fire(
							'Declined!',
							'Booking has been declined.',
							'success'
							)
							console.log('aaa')
							this.$emit('showModal', false)
 						}
				})
				.then(response=>{
					// this.$emit('showModal', false)
				})
			}

		}

	}
	
</script>